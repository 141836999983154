import { graphql } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";
import styled from "styled-components";

import { Layout, PostCard } from "../components";
import { MetaData } from "../../../components/meta";

const InnerPost = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
`;

const PostFeed = styled.div`
    position: relative;
    display: grid;
    gap: 4.8vmin 4vmin;
    grid-template-columns: repeat(6, 1fr);
    padding: max(4.8vmin, 36px) 0 0;
    margin-top: 4vmin;

    .post-card {
        &:nth-child(2),
        &:nth-child(3) {
            grid-column: span 2;
        }
        &:not(.post-card-large):not(.post-card-full):not(.dynamic):not(
                .no-image
            )
            .post-card-excerpt {
            -webkit-line-clamp: 2;
        }
        position: relative;
        grid-column: span 2;
        display: flex;
        flex-direction: column;
        background-size: cover;
        word-break: break-word;
    }

    @media (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1001px) {
        .post-card-large {
            grid-column: span 6;
            display: grid;
            grid-gap: 4vmin;
            grid-template-columns: repeat(3, 1fr);
            border-top: 0;
        }
    }
`;

const PostCardContent = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @media (min-width: 1001px) {
        grid-column: span 1;
    }
`;

const PostCardContentLink = styled.div`
    position: relative;
    display: block;
    color: var(--color-darkgrey);
    @media (min-width: 1001px) {
        position: relative;
        display: block;
        color: var(--color-darkgrey);
    }
`;

const PostCardHeader = styled.div`
    @media (min-width: 1001px) {
        margin-top: 0;
    }
`;

const PostCardTitle = styled.h2`
    margin: 0;
    font-size: 2.6rem;
    font-weight: 800;
    @media (min-width: 1001px) {
        margin-top: 0;
        font-size: 4.4rem;
        line-height: 1;
    }
`;

const PostCardExcert = styled.div`
    display: -webkit-box;
    overflow-y: hidden;
    margin-top: 12px;
    max-width: 720px;
    font-size: 1.6rem;
    line-height: 1.5;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
    @media (min-width: 1001px) {
        margin-top: 16px;
    }
`;

/**
 * Tag page (/tag/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 * @param root0
 * @param root0.data
 * @param root0.location
 */
const Tag = ({ data, location }) => {
    const tag = data.ghostTag;
    const posts = data.allGhostPost.edges;

    return (
        <>
            <MetaData data={data} location={location} type="series" />
            <Layout isHeadTransparent={tag.feature_image !== null}>
                <main className="site-main">
                    <InnerPost>
                        <PostFeed>
                            <section className="post-card post-card-large">
                                <PostCardContent>
                                    <PostCardContentLink>
                                        <PostCardHeader>
                                            <PostCardTitle>
                                                {tag.name}
                                            </PostCardTitle>
                                        </PostCardHeader>
                                        <PostCardExcert>
                                            {tag.description}
                                        </PostCardExcert>
                                    </PostCardContentLink>
                                </PostCardContent>
                            </section>
                            {posts.map(({ node }) => (
                                <PostCard key={node.id} post={node} />
                            ))}
                        </PostFeed>
                    </InnerPost>
                </main>
            </Layout>
        </>
    );
};

Tag.propTypes = {
    data: PropTypes.shape({
        ghostTag: PropTypes.object.isRequired,
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default Tag;

export const pageQuery = graphql`
    query GhostTagQuery($slug: String!) {
        ghostTag(slug: { eq: $slug }) {
            ...GhostTagFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
